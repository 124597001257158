<template>

    <div class="card" style="background-color: #fffaec; box-shadow: none; webkit-box-shadow:none;">
        <div class="card-image" style="padding-top:40px" >
            <figure class="image" >
                <img :src="getImgUrl(image)" class="is-rounded" style="width: 200px"/>
            </figure>
        </div>
        <div class="card-content">

            <div class="content">
                <p class="title is-4">{{name}}</p>

                <p v-on:click="awesome=true" v-if="!awesome">
                    {{beschreibung | truncate(120, '...')}}
                    <a>Weiterlesen</a>

                </p>

                <div v-if="awesome" v-on:click="awesome=false">
                    <p>
                        <span v-html="beschreibung"/>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>



    export default {

        name: 'Zeugnis',
        props: {
            name: String,
            beschreibung: String,
            image: String,
        },
        methods: {
            getImgUrl(pic) {
                return require('../assets/' + pic)
               // return pic;
            }
        },
        mounted() {
        },
        data() {
            return {
                isOpen: false,
                awesome: false
            }
        },

    }
</script>


